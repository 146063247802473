.benefit-card {
  max-width: 340px;
  min-height: 300px;
}
.benefit-container {
  gap: 32px;
  margin-top: 64px;
}
.benefit-section-title {
  max-width: 767px;
}
.border-section-card {
  border-radius: 16px;
}
.home-section-content-width {
  padding: 0px 32px;
  width: 640px;
}
.home-section-height {
  height: 659px;
}
.slider-section-card {
  border-radius: 6px;
}
.step-card {
  align-items: center;
  background-color: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 384px;
  min-height: 285px;
  text-align: center;
}
.step-circle {
  align-items: center;
  background-color: #1d5bd6;
  border-radius: 50px;
  color: white;
  display: flex;
  height: 64px;
  justify-content: center;
  width: 64px;
}
.step-title {
  max-width: 650px;
}
@media only screen and (max-width: 1226px) {
  .home-hero-container {
    display: flex;
    flex-direction: column;
  }
  .home-hero-left {
    display: flex;
    justify-content: space-around;
    padding: 80px 32px;
    width: 100% !important;
  }
  .home-hero-right {
    display: flex;
    height: 555px;
    justify-content: space-around !important;
    width: inherit;
  }
  .home-hero-right > img {
    animation: fadeIn 5s !important;
    position: inherit !important;
  }
  .home-hero-right-section {
    padding: 0;
  }
  .home-section-height {
    height: 100%;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
@media only screen and (max-width: 1300px) {
  .home-hero-left-text-container {
    padding-left: 64px;
    padding-right: 0;
  }
  .home-hero-right-section {
    padding-left: 0;
    padding-right: 64px;
  }
}
@media only screen and (max-width: 550px) {
  .hero-heading {
    font-size: 2rem !important;
  }
  .home-hero-left {
    display: flex;
    justify-content: space-around;
    padding: 40px 32px;
    width: 100% !important;
  }
  .home-hero-left > div > div > a > button {
    width: 100%;
  }
  .home-hero-left-text-container {
    padding: 0;
  }
  .scroller {
    height: 1.1em;
    line-height: 1.13em;
    margin-left: 10px;
    margin-top: 13px;
    overflow: hidden;
    position: relative;
    width: 240px;
  }

  .scroller > div {
    animation: slide 14.5s infinite;
    font-weight: bold;
    position: absolute;
    top: -4px;
  }
  @keyframes slide {
    0% {
      top: 10;
    }
    25% {
      top: -1.3em;
    }
    50% {
      top: -2.5em;
    }
    75% {
      top: -3.6em;
    }
  }
}
@media only screen and (max-width: 690px) {
  .hero-heading {
    font-size: 3rem;
  }
  .hero-slider {
    display: flex;
    height: 100%;
    position: relative;
    width: 300px;
  }
  .home-hero-right {
    display: flex;
    height: 300px;
    justify-content: space-around !important;
    width: inherit;
  }
  .home-hero-right-section {
    display: flex;
    justify-content: center;
  }
  .home-section-content-width {
    width: 100% !important;
  }
  .scroller {
    height: 1.2em;
    line-height: 1.2em;
    margin-left: 10px;
    margin-top: 14px;
    overflow: hidden;
    position: relative;
    width: 240px;
  }
  .scroller > div {
    animation: slide 14.5s infinite;
    font-weight: bold;
    position: absolute;
    top: 0;
  }
  @keyframes slide {
    0% {
      top: 0;
    }
    25% {
      top: -1.32em;
    }
    50% {
      top: -2.5em;
    }
    75% {
      top: -3.7em;
    }
  }
}
