:root {
  --red100: #ffadad;
  --red200: #f86565;
  --blue100: #d6e9ff;
  --blue700: #0e84ff;
  --blue800: #1d5bd6;
  --neutral0: #ffffff;
  --neutral1000: #000000;
  --neutral200: #f1f3f9;
  --neutral300: #e1e6ef;
}
.bg-blue-100 {
  background-color: var(--blue100);
}
.bg-blue-700 {
  background-color: var(--blue700);
}
.bg-blue-800 {
  background-color: var(--blue800) !important;
}
.bg-inherit,
.bg-inherit:hover {
  background-color: inherit !important;
}
.bg-neutral-0 {
  background-color: var(--neutral0) !important;
}
.bg-neutral-200 {
  background-color: var(--neutral200);
}
