@media only screen and (max-width: 550px) {
  .hero-left > div > div > a > button {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .hero-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .hero-left {
    padding: 80px 32px;
    width: 100% !important;
  }
  .hero-right {
    width: 100% !important;
  }
  .hero-right > img {
    animation: faderIn 5s !important;
    position: inherit !important;
  }
  .section-height {
    height: 100%;
  }
  @keyframes faderIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 395px) {
  .scroller {
    height: 1.175em !important;
  }
  .scroller-word-1 {
    line-height: 31px !important;
    padding-bottom: 4px !important;
  }
}
