.card-container {
  border-radius: 2rem;
  box-shadow: 0 0.063rem 0.188rem 0 rgba(0, 0, 0, 0.1),
    0 0.063rem 0.125rem 0 rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  height: 575px;
  margin: 1.25rem 0.5rem !important;
  max-width: 28rem;
  padding-bottom: 2rem;
  padding-inline-end: 2rem;
  padding-inline-start: 2rem;
  padding-top: 2rem;
  position: relative !important;
  width: 385px !important;
}
.chakra-switch__track[data-checked] {
  background: #1d5bd6 !important;
}
.duration-text {
  align-self: self-end !important;
  margin-left: 0.625rem;
}
.feature-list {
  list-style-position: outside;
  list-style-type: none;
  margin-top: 1.5rem;
}
.feature-list .item {
  gap: 14px;
  max-width: 260px;
}
.feature-list > li {
  margin-bottom: 0.625rem;
}
.popular-badge {
  background-color: #d6e9ff;
  border-radius: 0.5rem;
  color: #1d5bd6;
  font-size: 1rem;
  font-weight: 500;
  max-height: 3rem;
  padding: 0.688rem 0.75rem;
  width: fit-content;
}
.popular-container {
  display: flex;
  justify-content: space-between;
}
.tier-button {
  border-radius: 0.5rem;
  color: white;
  min-height: 3.5rem;
  width: 100% !important;
}
.tier-button-container {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
}
