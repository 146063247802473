.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-around {
  align-items: center;
  display: flex;
  justify-content: space-around;
}
.flex-baseline {
  align-items: baseline;
}
.flex-between {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.flex-center {
  align-items: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-column-center {
  justify-content: center;
}
.flex-end {
  align-items: flex-end;
  display: flex;
  justify-content: center;
}
.flex-inline {
  display: inline-flex;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-right {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-start {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.flex-wrap {
  flex-wrap: wrap;
}
.height-100 {
  height: 100%;
}
.height-25 {
  height: 25%;
}
.height-50 {
  height: 50%;
}
.height-75 {
  height: 75%;
}
.height-fit {
  height: fit-content;
}
.overflow-x {
  overflow-x: auto;
}
.overflow-y {
  overflow-y: auto;
}
.pos-absolute {
  position: absolute;
}
.pos-relative {
  position: relative;
}
.width-100 {
  width: 100%;
}
.width-25 {
  width: 25%;
}
.width-50 {
  width: 50%;
}
.width-75 {
  width: 75%;
}
.width-fit {
  width: fit-content;
}
