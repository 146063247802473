.h2 {
  color: #1d5bd6;
  margin: 0 auto;
  max-width: 590px;
  padding: 50px 10px 30px;
  text-align: center;
}
.navigation,
.swiper-pagination {
  display: none;
}
.slide-container {
  display: flex;
  gap: 4px;
  height: 100%;
  padding: 35px 0;
}
.slide-content-left {
  display: flex;
  gap: 10px;
  min-width: 175px;
}
.slide-content-right {
  overflow: hidden;
  text-overflow: ellipsis;
}
.slide-photo {
  border-radius: 50%;
}
.slide-text-container {
  display: flex;
  flex-direction: column;
}
.slide-text-name {
  font-weight: 700;
}
.slide-text-title {
  color: #6d7280;
}
.swiper {
  height: 260px;
  width: 80%;
}
.swiper-button-next::after {
}
.swiper-button-next::after,
.swiper-button-prev::after {
  color: #111827;
  font-size: 12px;
  font-weight: 800;
  z-index: 1;
}
.swiper-button-prev::after {
}
.swiper-slide {
  align-items: center;
  background-color: #f3f4f6;
  border-radius: 6px;
}
.testimonials-container {
  height: 510px;
  min-width: 300px;
}
@media only screen and (max-width: 1000px) and (min-width: 900px) {
  .slide-container {
    gap: 10px;
  }
  .slide-content-left {
    flex-direction: column;
    min-width: 115px;
  }
}
@media only screen and (max-width: 560px) {
  .slide-container {
    flex-direction: column;
    gap: 10px;
    padding: 35px 40px;
  }
}
@media only screen and (max-width: 768px) {
  .swiper-container {
    flex-direction: column;
  }
  .slide-container {
    gap: 10px;
  }
  .swiper-pagination,
  .swiper-pagination-clickable,
  .swiper-pagination-bullets,
  .swiper-pagination-horizontal {
    display: block;
    justify-content: center;
    z-index: 1;
  }
  .swiper-pagination-bullet {
    height: 12px;
    width: 12px;
  }
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    justify-content: center;
    margin: 20px auto;
    text-align: center;
    width: 50%;
  }
}
@media only screen and (max-width: 768px) and (min-width: 500px) {
  .swiper-button-next::after,
  .swiper-button-prev::after {
    display: none;
  }
}
@media only screen and (max-width: 900px) and (min-width: 768px) {
  .slide-container {
    gap: 10px;
  }
  .slide-content-left {
    flex-direction: row;
    min-width: 175px;
  }
  .swiper {
    width: 80%;
  }
}
