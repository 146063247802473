.about-header {
  height: 400px;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

.about-value-container {
  max-width: 1000px;
}

.about-values-main-conatiner {
  width: 100%;
}

.about-container > div {
  padding: 32px 0;
}

.about-value-container {
  max-width: 670px;
}

.about-text-container {
  max-width: 670px;
  text-align: center;
}

.about-banner-img {
  max-width: 1500px;
  max-height: 50vh;
}

@media only screen and (max-width: 992px) {
  .about-banner-img {
    max-width: 800px;
  }
}
