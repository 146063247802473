.chakra-popover__arrow {
  top: 1px !important;
}
.chakra-popover__popper section {
  overflow: visible !important;
}
.font-size-2xl {
  font-size: 1.5rem;
}
.font-size-3xl {
  font-size: 1.875rem;
}
.font-size-3xl-b {
  font-size: 2rem;
}
.font-size-4xl {
  font-size: 2.25rem;
}
.font-size-4xl-b {
  font-size: 2.5rem;
}
.font-size-5xl {
  font-size: 3rem;
}
.font-size-6xl {
  font-size: 3.75rem;
}
.font-size-7xl {
  font-size: 4.5rem;
}
.font-size-8xl {
  font-size: 6rem;
}
.font-size-9xl {
  font-size: 8rem;
}
.font-size-lg {
  font-size: 1.125rem;
}
.font-size-md {
  font-size: 1rem !important;
}
.font-size-sm {
  font-size: 0.875rem;
}
.font-size-xl {
  font-size: 1.25rem;
}
.font-size-xs {
  font-size: 0.75rem;
}
.font-weight-black {
  font-weight: 900;
}
.font-weight-bold {
  font-weight: 700;
}
.font-weight-extrabold {
  font-weight: 800;
}
.font-weight-hairline {
  font-weight: 100;
}
.font-weight-light {
  font-weight: 300;
}
.font-weight-medium {
  font-weight: 500;
}
.font-weight-normal {
  font-weight: 400;
}
.font-weight-semibold {
  font-weight: 600 !important;
}
.font-weight-thin {
  font-weight: 200;
}
.hover-pointer:hover {
  cursor: pointer;
}
.hover-primary:hover {
  color: #1d5bd6;
}
.neutral-0 {
  color: #ffffff;
}
.outline-btn-1 {
  background-color: transparent !important;
  color: white !important;
}
.outline-btn-2 {
  background-color: transparent;
  border: 1px solid #1d5bd6 !important;
  color: #1d5bd6 !important;
}
.p1 {
  font-size: 1rem;
}
.p2 {
  font-size: 0.875rem;
}
.primary-color {
  color: #1d5bd6;
}
.text-center {
  text-align: center;
}
.text-color-gray {
  color: #6d7280;
}
.text-color-primary {
  color: #1d5bd6 !important;
}
.text-color-secondary {
  color: #ffffff !important;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-left {
  text-align: left;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-right {
  text-align: right;
}
