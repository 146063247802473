* {
  scroll-behavior: smooth !important;
}
.chakra-avatar__excess {
  display: none !important;
}
.content-container {
  background-color: #ffffff;
}
.section-content-padding {
  padding-bottom: 80px;
  padding-top: 80px;
}
.section-content-width {
  padding: 0px 32px;
  width: 640px;
}
.section-height {
  height: 659px;
}
.section-small {
  max-height: 200px;
  padding-top: 48px;
  padding-bottom: 48px;
  display: flex;
  justify-content: space-around;
}
::-webkit-scrollbar {
  background-color: transparent !important;
  height: 7px;
  margin-right: 10px;
  width: 10px;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #6e7480;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:active {
  background-color: #545a64;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #8d939f;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
  background-color: transparent !important;
}
@media only screen and (max-width: 767px) {
  .section-content-width {
    padding: 0px 16px;
    width: 640px;
  }
  .section-content-width > h1 {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
  }
  .section-small {
    display: flex;
    justify-content: center !important;
    gap: 16px;
  }
}
body {
  color: white;
  font-family: "Works Sans", sans-serif;
  margin: 0;
  moz-osx-font-smoothing: grayscale;
  overflow: overlay;
  overflow-x: hidden;
  padding-top: 70px;
  webkit-font-smoothing: antialiased;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
button {
  border-radius: 0.375rem !important;
  font-size: 0.875rem !important;
  font-weight: 600;
}
