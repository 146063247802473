.hero-slider {
  display: flex;
  height: 100%;
  position: relative;
}
.hero-slider .img1 {
  animation: slide1 13s infinite;
}
.hero-slider .img2 {
  animation: slide2 13s infinite;
}
.hero-slider .img3 {
  animation: slide3 13s infinite;
}
.hero-slider .img4 {
  animation: slide4 13s infinite;
}
.hero-slider img {
  flex-shrink: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  width: auto !important;
}
.scroller {
  height: 1.2em;
  line-height: 1.2em;
  margin-left: 10px;
  margin-top: 14px;
  overflow: hidden;
  position: relative;
  width: 350px;
}
.scroller > div {
  animation: slide 14.5s infinite;
  font-weight: bold;
  position: absolute;
  top: 0;
}

@media only screen and (max-width: 550px) {
  .scroller {
    width: 164px !important;
  }
}

@keyframes slide {
  0% {
    top: 0;
  }
  25% {
    top: -1.2em;
  }
  50% {
    top: -2.4em;
  }
  75% {
    top: -3.6em;
  }
}
@keyframes slide1 {
  0%,
  5%,
  15%,
  100% {
    opacity: 1;
  }
  25%,
  30%,
  40% {
    opacity: 0;
  }
  50%,
  60%,
  65% {
    opacity: 0;
  }
  75%,
  85% {
    opacity: 0;
  }
}
@keyframes slide2 {
  0%,
  5%,
  15%,
  100% {
    opacity: 0;
  }
  25%,
  30%,
  40% {
    opacity: 1;
  }
  50%,
  60%,
  65% {
    opacity: 0;
  }
  75%,
  85% {
    opacity: 0;
  }
}
@keyframes slide3 {
  0%,
  5%,
  15%,
  100% {
    opacity: 0;
  }
  25%,
  30%,
  40% {
    opacity: 0;
  }
  50%,
  60%,
  65% {
    opacity: 1;
  }
  75%,
  85% {
    opacity: 0;
  }
}
@keyframes slide4 {
  0%,
  5%,
  15%,
  100% {
    opacity: 0;
  }
  25%,
  30%,
  40% {
    opacity: 0;
  }
  50%,
  60%,
  65% {
    opacity: 0;
  }
  75%,
  85% {
    opacity: 1;
  }
}
