.checkout-container {
  background: var(--neutral0);
  padding: 48px 85px;
  border-radius: 16px;
  min-height: 660px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.checkout-form {
  width: 100%;
}

.checkout-btn {
  background-color: var(--blue800);
  color: var(--neutral0);
  font-weight: 500 !important;
  margin-top: 24px;
  padding: 10px 20px;
  width: 100%;
}

.checkout-heading-container {
  padding: 0 !important;
}

.checkout-overlay {
  background-color: rgba(0, 0, 0, 0.8);
}
.checkout-main-container {
  display: flex;
  flex-direction: row;
  padding: 80px 32px;
}

@media only screen and (max-width: 992px) {
  .checkout-container {
    padding: 48px 48px;
    min-height: 400px;
  }
}

@media only screen and (max-width: 768px) {
  .checkout-main-container {
    display: flex;
    flex-direction: column !important;
  }

  .checkout-left {
    padding: 0 !important;
    justify-content: space-around !important;
  }

  .checkout-sub {
    font-size: 1.25rem !important;
  }
  .checkout-heading-container {
    padding: 0 !important;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
}

.checkout-price-container {
  display: flex;
}
.checkout-rate {
  display: flex;
  align-self: end;
  color: #6e6e6e;
}
.checkout-text-container {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.checkout-email {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 0.75rem 0;
}
.checkout-email-label {
  margin-bottom: 0.25rem;
  font-size: 0.93rem;
  font-weight: 400;
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
    opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.checkout-email-input {
  border-radius: 5px !important;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
  text-overflow: ellipsis;
}

.checkout-header {
  display: flex;
  justify-content: center;
}

.checkout-price {
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
}
.checkout-form-container {
  flex: 1;
  display: flex;
  align-items: self-end;
}

.checkout-pay-btn {
  color: white;
  background-color: #979797 !important;
  font-size: 16px !important;
}
