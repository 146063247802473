.gap-spacer-1 {
  gap: 1px;
}
.gap-spacer-2 {
  gap: 2px;
}
.gap-spacer-3 {
  gap: 4px;
}
.gap-spacer-4 {
  gap: 8px;
}
.gap-spacer-5 {
  gap: 12px;
}
.gap-spacer-6 {
  gap: 16px;
}
.gap-spacer-7 {
  gap: 24px;
}
.gap-spacer-8 {
  gap: 32px;
}
.gap-spacer-9 {
  gap: 40px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 1px;
}
.mb-2 {
  margin-bottom: 2px;
}
.mb-3 {
  margin-bottom: 4px;
}
.mb-4 {
  margin-bottom: 8px;
}
.mb-5 {
  margin-bottom: 12px;
}
.mb-6 {
  margin-bottom: 16px;
}
.mb-7 {
  margin-bottom: 32px;
}
.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: 1px;
}
.mr-2 {
  margin-right: 2px;
}
.mr-3 {
  margin-right: 4px;
}
.mr-4 {
  margin-right: 8px;
}
.mr-5 {
  margin-right: 12px;
}
.mr-6 {
  margin-right: 16px;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 1px;
}
.p-2 {
  padding: 2px;
}
.p-3 {
  padding: 4px;
}
.p-4 {
  padding: 8px;
}
.p-5 {
  padding: 12px;
}
.p-6 {
  padding: 16px;
}
.p-7 {
  padding: 32px;
}
