.footer-copyright {
  font-size: 0.75rem;
}
.footer-content {
  align-items: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  margin: 55px 0px 80px;
  width: 100%;
}
.footer-link {
  padding: 0 16px;
}
.footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none !important;
  padding-bottom: 1.5rem;
  margin: 0 !important;
  padding: 0 !important;
  padding-bottom: 24px !important;
}
.footer-links-social {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none !important;
  padding-bottom: 1.5rem;
  margin: 0 !important;
  padding: 0 !important;
  padding-bottom: 24px !important;
}
.footer-logo {
  margin: 25px 0px;
}
.footer-main-container {
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #23272f;
}
.footer-social-icon {
  margin: 0px 6px;
}
.footer-social-icons {
  margin-bottom: 24;
}

@media only screen and (max-width: 31em) {
  .footer-link {
    text-align: center;
  }
  .footer-links {
    align-items: center;
    display: grid;
    flex-direction: column;
    grid-gap: 10px;
    justify-content: center;
    list-style: none !important;
    margin: 0 !important;
    padding: 0 !important;
    padding-bottom: 1.5rem;
    padding-bottom: 24px !important;
  }
}
